import React from 'react';
import './App.module.scss';
import BaseLayout from "./components/BaseLayout";
import { BrowserRouter } from "react-router-dom";
import { CookieNotice } from 'react-cookienotice'
import 'react-cookienotice/dist/style.css'

function App() {
   return (
      <div>
         <BrowserRouter>
            <BaseLayout />
            <CookieNotice
               onAcceptAllButtonClick={() => { }}
               onDeclineAllButtonClick={() => { }}
               titleLabel='🍪 Cookie consent'
               cookieExpiration={14}
               cookieName='x-lukasbahr-de'
            // readMoreLabel='Read more'
            // readMoreLink='https://lukasbahr.de/privacy'
            // readMoreInNewTab 
            />
         </BrowserRouter>
      </div>
   );
}


export default App;
