import self from "../img/self.png"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(39,201,63)"];

export const info = {
    firstName: "Lukas",
    lastName: "Bahr",
    initials: "LB",
    position: "a passionated Cloud Engineer",
    selfPortrait: self,
    gradient: `-webkit-linear-gradient(135deg, ${colors})`,
    baseColor: colors[0],
    miniBio: [
        {
            emoji: '🌲',
            text: 'based in black forest - southern Germany'
        },
        {
            emoji: "💼",
            text: "Cloud-Engineer and Consultant at dmTECH GmbH"
        },
        {
            emoji: '🚜',
            text: 'part-time farmer'
        }

    ],
    socials: [
        {
            link: "https://github.com/lukibahr",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://gitlab.com/lukasbahr",
            icon: "fa fa-gitlab",
            label: 'gitlab'
        },
        {
            link: "https://www.linkedin.com/in/lukas-bahr-99a486187/",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        },
        {
            link: "https://www.xing.com/profile/Lukas_Bahr",
            icon: "fa fa-xing",
            label: 'xing'
        },
        {
            link: "mailto:hello(at)lukasbahr.de",
            icon: "fa fa-envelope",
            label: 'mail'
        }

    ],
    bio: "I'm Lukas Bahr, working as a full-time Cloud-Engineer and Consultant, currently at dmTECH GmbH a 100% subsidiary of dm-drogerie markt located in Karlsruhe. I'm living on a farm in a little town in the black forest in the south of Germany. A fiber internet connection is the only thing that is missing in this beautiful area. Since I've started working in IT, in the early days I worked with Enterprise Applications, built CI/CD workflows and administered alot. My skills grew with containerization and learned that \"cloud\" is way more than simple online storage. Today, I'm working with containers, Kubernetes and technologies like serverless and Pub/Sub on various Hyperscalers.",
    skills:
    {
        proficientWith: ['Containerization', 'Kubernetes', 'Cloud', 'Microsoft Azure', 'Google GCP', 'GitLab CI/CD', 'Everything-as-Code', 'Terraform', 'Pulumi', 'Python', 'Golang', 'Web development'],
        exposedTo: []
    }
    ,
    hobbies: [
        {
            label: 'working with bits and bytes',
            emoji: '🖥️'
        },
        {
            label: 'doing farmers stuff',
            emoji: '🚜'
        }
    ],
    portfolio: [
    ]
}